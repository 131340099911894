import React from 'react'
import Layout from '../components/layout'

import saveTheDate from '../images/save_the_date.gif'


const SaveTheDate = () => (
  <Layout>
    <header id="header" >
      <div className="logo">
        <span className="icon fa-diamond"></span>
      </div>
      <div className="content">
        <div className="inner">
          <h2 className='align-center'>Three years postponed... it's finally happening!</h2>
          <p className='align-center'>
            Join us on Saturday May 27, 2023 at McMaster University, Hamilton, for our wedding ceremony and reception. <br /> Kindly save the date! Details and invitation to follow</p>

        </div>
      </div>
    </header>
    <img src={saveTheDate} alt="Save the date! May 27th 2023" width="100%" />

  </Layout>
)

export default SaveTheDate
